import React from "react";
import { Typography } from "@mui/material";

export const HomePage: React.FC = () => (
    <React.Fragment>
        <Typography variant="h3" component="h2">
        Welcome to our Couture Code Artisans
        </Typography>
        <Typography variant="body1" component="h3">
            where we specialize in delivering custom software solutions
            to help businesses achieve their goals. Our team of experienced software developers, designers, and
            project managers work together to create high-quality software products that are tailored to the
            specific needs of our clients.
        </Typography>
        <Typography variant="subtitle1" component="h3">
            We understand that every business has unique requirements, and that's why we take a collaborative
            approach to ensure that our solutions meet those needs. We work closely with our clients to understand
            their business processes and goals, and then design and develop software solutions that streamline
            those processes and help them achieve their goals.
        </Typography>
        <Typography variant="subtitle1" component="h3">
            We offer a wide range of software consulting services, including software development, software
            architecture design, project management, and quality assurance testing. Whether you need a
            complete software solution or just need help with a specific aspect of your project, our team is
            here to help.
        </Typography>
        <Typography variant="subtitle1" component="h3">
            At our Couture Code Artisans, we pride ourselves on delivering quality solutions that are reliable,
            scalable, and easy to use. We are committed to providing our clients with exceptional service and
            support throughout the entire software development process, from concept to delivery.
        </Typography>
        <Typography variant="subtitle1" component="h3">
            If you're looking for a trusted partner to help you create custom software solutions that drive
            business success, look no further than our Couture Code Artisans. Contact us today to learn more
            about our services and how we can help your business.
        </Typography>
    </React.Fragment>
);
