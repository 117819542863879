import './App.css';
import TopBar from './components/top-bar';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { BrowserRouter, Route, Routes, Link } from "react-router-dom";
import { HomePage } from './components/home-page';
import { Toolbar } from '@mui/material';
import { Box, Button } from '@mui/material';
import { NotFoundPage } from './components/not-found';
import { ServicesPage } from './components/services-page';
import { ContactPage } from './components/contact-page';

const theme = createTheme({
    palette: {
        primary: {
            main: '#000000',
        },
        secondary: {
            main: '#9c27b0',
        },
    }
});




function App() {
    return (
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <div className="App">
                    <header className="App-header">
                        <TopBar />
                    </header>
                    <div className='contents'>
                        <Routes>
                            <Route path="/" element={<HomePage />} />
                            <Route path="/services" element={<ServicesPage />} />
                            <Route path="/contact" element={<ContactPage />} />
                            <Route path="*" element={<NotFoundPage />} />
                        </Routes>
                    </div>
                    <footer>
                        <Toolbar style={{ marginLeft: 0, paddingLeft: 0 }}>
                            <Box>
                                <Link to="/services">
                                    <Button variant='text' color='primary' style={{ marginRight: 16 }}>
                                        Services
                                    </Button>
                                </Link>
                                <Button href='mailto:hello@couturecodeartisans.com'
                                    sx={{ marginRight: 16 }}
                                    >Contact</Button>
                            </Box>
                        </Toolbar>
                        &copy;2023 cedrus+thuja inc. / couture code artisans
                    </footer>
                </div>
            </BrowserRouter>
        </ThemeProvider>
    );
}

export default App;
