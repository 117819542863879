import React from "react";
import Typography from "@mui/material/Typography";
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';


const Item = styled(Paper)(({ theme }) => ({
  padding: '24px',
  color: theme.palette.text.secondary,
}));



export const ServicesPage: React.FC = () => {
  return (
    <div className="content-layout">
      <Typography variant="h3" component="h2">
        Services &amp; Capabilities
      </Typography>
      <Typography variant="body1" component="h3">
        Let us help you achieve your goals.
      </Typography>
      <Grid item xs={6}>
        <Box
          sx={{
            p: 2,
            display: 'grid',
            gridTemplateColumns: { md: '1fr 1fr' },
            gap: 2,
          }}
        >

          <Item elevation={3}>
            <Typography variant="h5" component="h4" color="secondary">
            Software Development
            </Typography>
            <Typography variant="body1" component="p">
              No project is too big or too small. Our team can handle front end, back end, integration, mobile, and web.
            </Typography>
          </Item>

          <Item elevation={3}>
            <Typography variant="h5" component="h4" color="secondary">
              Software Architecture Design
            </Typography>
            <Typography variant="body1" component="p">
              We work with you to understand your business to design the best software architecture for your needs. We follow industry best practices combined with decades of expereince to bring you solutions that work.
            </Typography>
          </Item>

          <Item elevation={3}>
            <Typography variant="h5" component="h4" color="secondary">
              Project Management
            </Typography>
            <Typography variant="body1" component="p">
              Just need someone with technical knowledge to manage your project? We can come in, get up to speed quickly and get the team delivering in no time.
            </Typography>
          </Item>

          <Item elevation={3}>
            <Typography variant="h5" component="h4" color="secondary">
              Web and Mobile Application Design, Build, Delivery, and Hosting.
            </Typography>
            <Typography variant="body1" component="p">
              We can do it all, from designing you application with you. Building including full testing and quality assurance. We can even handle deployment anf hosting of your application.
            </Typography>
          </Item>

        </Box>

      </Grid>
    </div>
  );
};
