import React from "react";
import Typography from "@mui/material/Typography";
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from '@mui/icons-material/Send';
import Stack from '@mui/material/Stack';
import { FormEvent } from "react";

const Item = styled(Paper)(({ theme }) => ({
  padding: '36px',
  color: theme.palette.text.secondary,
}));


export const ContactPage: React.FC = () => {
  const [name, setName] = React.useState<string>("");
  const [reason, setReason] = React.useState<string>("");
  const [message, setMessage] = React.useState<string>("");
  const [email, setEmail] = React.useState<string>("");

  const handleSend = (e:FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log(name);
    console.log(email);
    console.log(reason);
    console.log(message);
  };

  return (
    <div className="content-layout">
      <Typography variant="h3" component="h2">
        Let us know what you need
      </Typography>
      <form onSubmit={handleSend}>
      <Grid item xs={6}>
        <Box
          sx={{
            p: 2,
            display: 'grid',
            gridTemplateColumns: { md: '1fr' },
            gap: 2,
          }}
        >

          <Item elevation={3}>
          <FormControl fullWidth sx={{ m: 1 }}>
              <InputLabel htmlFor="name">Name</InputLabel>
              <OutlinedInput required
                id="name"
                label="Name"
                color="secondary"
                value={name}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setName(event.target.value as string);
                }}
              />
              <FormHelperText id="filled-weight-helper-text">Required</FormHelperText>
            </FormControl>
            <FormControl fullWidth sx={{ m: 1 }}>
              <InputLabel htmlFor="name">Email</InputLabel>
              <OutlinedInput required
                id="email"
                label="Email"
                color="secondary"
                value={email}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setEmail(event.target.value as string);
                }}
              />
              <FormHelperText id="filled-weight-helper-text">Required</FormHelperText>
            </FormControl>
            <FormControl fullWidth sx={{ m: 1 }}>
              <InputLabel id="reason-label">Reason For Contact</InputLabel>
              <Select
                labelId="reason-label"
                id="reason"
                required
                value={reason}
                label="Reason For Contact"
                fullWidth
                color="secondary"
                onChange={(event: SelectChangeEvent<string>) => {
                  console.log(event.target.value);
                  setReason(event.target.value as string);
                }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value="Project Inquiry">Project Inquiry</MenuItem>
                <MenuItem value="Support Request">Support Request</MenuItem>
                <MenuItem value="Fanmail">Fanmail</MenuItem>
              </Select>
              <FormHelperText>Required</FormHelperText>
            </FormControl>
            <FormControl fullWidth sx={{ m: 1 }}>
              <InputLabel htmlFor="name">Message</InputLabel>
              <OutlinedInput required
                id="message"
                label="Message"
                value={message}
                color="secondary"
                multiline
                rows={4}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setMessage(event.target.value as string);
                }}
              />
              <FormHelperText>Required</FormHelperText>
            </FormControl>

            <Stack width="auto" direction="row" spacing={2} paddingTop={2} alignItems="flex-end">
              <Button variant="outlined" startIcon={<DeleteIcon />}>
                Cancel
              </Button>
              <Button type="submit" variant="contained" endIcon={<SendIcon />}>
                Send
              </Button>
            </Stack>
          </Item>
        </Box>
      </Grid>
      </form>
    </div>
  );
};
